import React, { useState, useEffect, useContext } from "react";
import Translation from "utils/Translation";
import { HotelContext } from "context/HotelContext";
import BreadCrumb from "component/Breadcrumb";
import { useAuth } from "react-oidc-context";
import SubscriptionBarChartComponent from "component/SubscriptionBarChartComponent";

const Abonnement = () => {
  const keycloak = useAuth();
  const { hId } = useContext(HotelContext);
  const [chartData, setChartData] = useState([]);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const formatSubsChartData = (data) =>
    data.map((item) => ({
        month: item.quarter,
        totalSavings: Math.round(Number(item.totalSavings)) || 0,
        realCost: Math.round(Number(item.realCost)) || 0,
        provCost: Math.round(Number(item.provCost)) || 0,
        regularization: Math.round(Number(item.regularization)) || 0,
      }));

      const fetchSubStats = async () => {
        if (!keycloak.isAuthenticated || !keycloak.user?.access_token || !hId) {
          setHasError(true);
          setLoading(false);
          return;
      }

        try {
            setLoading(true);
            setNoDataAvailable(false);

            const response = await fetch(`/api/hotels/${hId}/substats/`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${keycloak.user.access_token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
    
            if (response.ok) {
                if (data.data.success === "FAIL" && data.data.message === "No data found for substats.") {
                    setNoDataAvailable(true);
                    setStats([]);
                    setChartData([]);
                } else {
                    setStats(data.data.stats);
                    setChartData(formatSubsChartData(data.data.chartData));
                }
                setHasError(false);
            } else {
                setHasError(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };    

  useEffect(() => {
    if (keycloak.isAuthenticated && hId) {
      fetchSubStats();
    }
  }, [keycloak.isAuthenticated, hId]);

  const renderRegularizationText = (regularization) => {
    if (regularization === null || regularization === undefined) return "Données en cours d’ajout…";
    if (regularization > 0) return "Montant de régulation à déduire sur la prochaine mensualité";
    if (regularization < 0) return "Montant de régulation à facturer sur la prochaine mensualité";
    return "Bon montant provisionné : pas de régularisation à venir";
  };

  return (
    <div className="Dashboard">
      <BreadCrumb text="Abonnement" />
      <h1 className="h1-page">
        <Translation tag="Abonnement" />
      </h1>
      <h2 className="h2-page">
        <Translation tag="Abonnement_subtitle" />
      </h2>

      {loading ? (
        <div>Chargement des données...</div>
      ) : hasError ? (
        <div>Erreur lors du chargement des données. Veuillez réessayer.</div>
      ) : noDataAvailable ? (
        <div className="chart-loader">
          <p className="chart-loader-text">Les données ne sont pas encore disponibles pour cette période.</p>
        </div>
      ) : (
      <div>
        <div className="table-container">
          <table className="custom-table">
            <thead className="table-header">
              <tr className="header-row">
                <th className="table-title column-large">Trimestre</th>
                <th className="table-title">Économies totale réalisées</th>
                <th className="table-title">Coût selon contrat</th>
                <th className="table-title">Coût provisionné</th>
                <th className="table-title">Régularisation</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {stats.length > 0 ? (
                stats.map((quarter, index) => (
                  <tr className="body-row" key={index}>
                    <td className="table-cell">{quarter.quarter}</td>
                    <td className="table-cell">{quarter.totalSavings} €</td>
                    <td className="table-cell">{quarter.realCost} €</td>
                    <td className="table-cell">{quarter.provCost} €</td>
                    <td className="table-cell">
                      {quarter.regularization} €
                      <div className="regularization-text">{renderRegularizationText(quarter.regularization)}</div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">Aucune donnée disponible</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>


          <div className="Dashboard-part-2">
            <div className="Chart_dashboard">
              <div className="chart-header">
                <h1 className="Title_graph">
                  <Translation tag="SubGraphTitle" />
                </h1>
              </div>

              {loading ? (
                <div className="chart-loader">
                  <p className="chart-loader-text">Chargement des données...</p>
                </div>
              ) : hasError ? (
                <div className="chart-loader">
                  <p className="chart-loader-text">Erreur lors du chargement des données. Veuillez réessayer.</p>
                </div>
              ) : noDataAvailable ? (
                <div className="chart-loader">
                  <p className="chart-loader-text">Les données ne sont pas encore disponibles pour cette période.</p>
                </div>
              ) : chartData && chartData.length > 0 ? (
                <SubscriptionBarChartComponent
                  data={chartData}
                />
              ) : (
                <div className="chart-loader">
                  <p className="chart-loader-text">Pas de données disponibles pour cette période.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Abonnement;
