import React, { useContext, useEffect, useState } from "react";
import { HotelContext } from "context/HotelContext";

const translation = {
    since_beginning: { fr: "Depuis le début", en: "Since the beginning" },
    since_year: { fr: "Cette année", en: "This year" },
    since_quarter: { fr: "Ce trimestre", en: "This quarter" },
    Reglages: { fr: "Réglages", en: "Settings" },
    Hotel_Info: { fr: "Informations de l’hôtel", en: "Hotel information" },
    Mon_profil: { fr: "Mon profil", en: "My Informations" },
    Mes_hotels: { fr: "Mes hôtels", en: "My hotels" },
    liste_hotel: {
        fr: "Voici la liste de l’ensemble des hôtels auxquels vous avez accès",
        en: "Here is the list of all the hotels to which you have access",
    },
    Your_hotel: { fr: "Votre hôtel", en: "Your Hotel" },
    mes_hotels: { fr: "Mes hotels", en: "My Hotel" },
    validate: { fr: "Valider", en: "Validate" },
    No_donation: {
        fr: "Vous n'avez pas encore fait de dons",
        en: "You didn't make any donations yet",
    },
    No_rooms: { fr: "Aucune chambre trouvée", en: "No rooms finded" },
    Filter_by_tag: { fr: "Filtrer par version :", en: "Filter by version :" },
    All_tags: { fr: "Toutes les versions", en: "All versions" },
    Room_nb: { fr: "Chambre", en: "Room" },
    Dashboard_title: { fr: "Tableau de bord", en: "Dashboard" },
    Conso_title: { fr: "Mes consommations", en: "My consumptions" },
    Cons_subtitle: { fr: "Consommation d'eau", en: "Water consumption" },
    Eco_title: { fr: "Mes économies", en: "My savings" },
    // Eco_title: { fr: "Consommation sur la période", en: "Consumption over the period" },
    Rooms_title: { fr: "Mes chambres", en: "My rooms" },
    Statistique: { fr: "Statistiques", en: "Statistics" },
    See_more: { fr: "En savoir plus", en: "Learn more" },
    Saving_quarter: { fr: "Économies réalisé sur le trimestre", en: "Savings made during the quarter" },
    real_cost: { fr: "Coût réel (50% des économies)", en: "Actual cost (50% of savings)" },
    Temoin: { fr: "Témoins", en: "Blind" },
    Essentiel: { fr: "Essentielle", en: "Essential" },
    Experience: { fr: "Expérience", en: "Experience" },
    paid: { fr: "Coût provisionnés sur le trimestre", en: "Provisioned cost during the quarter" },
    regularisation: {
        fr: "Régularisation à venir",
        en: "Regularization to come",
    },
    Water_savings: { fr: "Eau économisée", en: "Water saved" },
    kWh_savings: { fr: "kWh économisés", en: "KWh saved" },
    perc_savings: { fr: "Économies moyennes", en: "Average savings" },
    Energie_euro: { fr: "Energie économisée (€)", en: "Energy saved (€)" },
    Eau_euro: { fr: "Eau économisée (€)", en: "Water saved (€)" },
    Money_savings: { fr: "Argent économisé", en: "Money saved" },
    //Dashboard_Savings: { fr: "Economies sur la période", en: "Annual savings" },
    Period: { fr: "Sur la période du", en: "Over the period from" },
    To: { fr: "au", en: "to" },
    Savings_period: {
        fr: "Évolution des économies d'eau et d'énergie sur la période",
        en: "Evolution of water and energy savings over the period",
    },
    Dashboard_period: {
        fr: "Économies financières sur la période",
        en: "Financial savings over the period",
    },
    Consumption_period: {
        fr: "Evolution des consommations d'eau et d'énergie",
        en: "Consumption over the period",
    },
    Percent_obj: { fr: "% d'objectif fixé", en: "% of target set" },
    Number_Shower: {
        fr: "Nombre de douches prises",
        en: "Number of showers taken",
    },
    Average_obj: { fr: "Objectif moyen", en: "Average target" },
    Average_temp: { fr: "Température moyenne", en: "Average temperature" },
    Conso_moy: {
        fr: "Consommations moyenne / douche",
        en: "Average consumption / shower",
    },
    Energie: { fr: "Energie (kWh)", en: "Energy (kWh)" },
    Eau: { fr: "Eau (L)", en: "Water (L)" },
    Temperature: { fr: "Température (°C)", en: "Temperature (°C)" },
    Room_etagesList: { fr: "Étages", en: "Floors" },
    Search: { fr: "Rechercher", en: "Search" },
    Room_floor: { fr: "Étage", en: "Floor" },
    Room_searchBar: { fr: "Rechercher...", en: "Search..." },
    Room_stat: { fr: "Mes statistiques", en: "My statistics" },
    Room_showersTaken: {
        fr: "Nombre de douches prises",
        en: "Number of showers taken",
    },
    Room_averageCons_Shower: {
        fr: "Consommations moyenne par douche",
        en: "Average consumption per shower",
    },
    Room_averageTemp: { fr: "Température moyenne", en: "Average temperature" },
    Room_savings: { fr: "Économies", en: "Savings" },
    Room_subtitle: {
        fr: "Consommation sur la période",
        en: "Consumption over the period",
    },
    Room_graph_title: {
        fr: "Evolution des consommations d'eau et d'énergie",
        en: "Evolution of water and energy consumption",
    },
    Room_subtitle_bis: {
        fr: "Economie sur la période",
        en: "Savings over the period",
    },
    Room_graph_title_bis: {
        fr: "Evolution des économies d'eau et d'énergie",
        en: "Evolution of water and energy savings",
    },
    Gain: { fr: "Gain (€)", en: "Earnings (€)" },
    Eau_reverse: { fr: "Eau reversée", en: "Water reversed" },
    Customer_xp_title: {
        fr: "Votre expérience client",
        en: "Your customer experience",
    },
    Customer_xp: {
        fr: "Votre expérience client",
        en: "Your customer experience",
    },
    avis_clients: { fr: "Vos avis clients", en: "Your customer reviews" },
    Rooms_typology: { fr: "Vos typologies de chambre", en: "Your room types" },
    // People: { fr: "personnes", en: "people"},
    Water_accessed: {
        fr: "Personnes avec accès à l'eau",
        en: "Access to water",
    },
    Certificats: { fr: "Certificats", en: "certificates" },
    Delivrance: { fr: "Date de délivrance", en: "Delivery date" },
    Periode: { fr: "Période", en: "Time period" },
    water_give: { fr: "Litres reversés", en: "Liters given" },
    hotel_access: {
        fr: "Pour accéder à votre hôtel, il faut se connecter",
        en: "To access your hotel, you need to log in",
    },
    Loading: { fr: "Chargement...", en: "Loading..." },
    Erreur: { fr: "Une erreur", en: "An error" },
    No_hotel: {
        fr: "Vous n'avez aucun hotel",
        en: "You don't have any hotels",
    },
    Saving: { fr: "Enregistrement en cours...", en: "Saving in progress..." },
    Delete: {
        fr: "L'hôtel a bien été supprimé",
        en: "The hotel has been removed",
    },
    Back_hotel: { fr: "Retour à mes hôtels", en: "Back to my hotels" },
    Delete_hotel: {
        fr: "Êtes vous sûr de vouloir supprimer l'hotel?",
        en: "Are you sure you want to delete the hotel?",
    },
    Cancel: { fr: "Annuler", en: "Cancel" },
    Delete_H: { fr: "Supprimer l'hôtel", en: "Delete hotel" },
    Hotel_Name: { fr: "Nom de l'hôtel", en: "Name of the hotel" },
    Intern_name: { fr: "Localisation", en: "Location" },
    DetailsHotelTitle: { fr: "Informations supplémentaires", en: "More imformations" },
    Coordonnees: { fr: "Coordonnées", en: "Contact details" },
    Hotel_place: { fr: "Localisation de l'hôtel", en: "Hotel location" },
    Address: { fr: "Adresse", en: "Address" },
    heatingType: { fr: "Type de chauffage", en: "Heating type" },
    priceWater: { fr: "Prix de l'eau (pour 1000L)", en: "Water price (for 1000L)" },
    priceEnergy: { fr: "Prix de l'énergie (par kWh)", en: "Energy price (per kWh)" },
    Experience: { fr: "Version Expérience ", en: "Experience version" },
    Consumption_graph: {
        fr: "Consommation des différentes typologies de chambre",
        en: "Consumption of different room types",
    },
    Essentiel: { fr: "Version Essentielle ", en: "Essentiel version" },
    Error_loading_rooms: { fr: "Erreur lors du chargement des chambres.", en: "Error loading rooms" },
    Loading_rooms: { fr: "Chargement des chambres...", en: "Loading rooms..." },
    moyenne_temoin: {
        fr: "Moyenne des chambres témoins",
        en: "Average of control rooms",
    },
    moyenne_essentiel: {
        fr: "Moyenne des chambres essentielles",
        en: "Average of essentials rooms",
    },
    moyenne_experience: {
        fr: "Moyenne des chambres expériences",
        en: "Average of experiences rooms",
    },

    Default_obj: { fr: "Objectif par défaut ", en: "Default goal" },
    Propose_screen: {
        fr: "Objectif sur la tablette ",
        en: "Goal on the tablet",
    },
    Ressource: { fr: "Ressources", en: "Resources" },
    Room_signage: { fr: "Signalétique en chambre ", en: "In-room signage" },
    Couts_euro: { fr: "Coût de l'abonnement (€)", en: "Subscription cost (€)" },
    Regularisation_euro: { fr: "Régularisation (€)", en: "Adjustment (€)" },
    City: { fr: "Ville", en: "City" },
    PC: { fr: "Code postal", en: "Postal code" },
    Abonnement: { fr: "Mon abonnement", en: "My subscription" },
    Abonnement_subtitle: { fr: "Bilan sur le trimestre", en: "Quarterly report" },
    Country: { fr: "Pays", en: "Country" },
    Stars: { fr: "Etoiles", en: "Stars" },
    Ranking: { fr: "Classement de l'hôtel", en: "Hotel ranking" },
    Back: { fr: "Retour", en: "Back" },
    Construction: {
        fr: "Page en construction...",
        en: "Page under construction...",
    },
    Identifiant: { fr: "Identifiant", en: "User ID" },
    Email: { fr: "votre adresse email", en: "Email address" },
    Email_invalid: { fr: "Email invalide", en: "Email address invalid" },
    Informations: { fr: "Informations", en: "Information" },
    About_you: { fr: "Sur vous", en: "About you" },
    First_name: { fr: "Prénom", en: "First name" },
    Last_name: { fr: "Nom", en: "Last name" },
    birthdate: { fr: "Date de naissance", en: "Birth date" },
    Job_title: { fr: "Nom de poste", en: "Job Title" },
    Refuse_invt: {
        fr: "Vous avez bien refusé l'invitation",
        en: "You declined the invitation",
    },
    Valid_decline_invt: {
        fr: "Vous êtes sur de vouloir décliner l'invitation?",
        en: "Are you sure you want to decline the invitation?",
    },
    Accept_invt: {
        fr: "Vous avez accepté l'invitation",
        en: "You have accepted the invitation",
    },
    Select: { fr: "Selectionner", en: "Select" },
    Accept_invitation: { fr: "Accepter l'invitation", en: "Accept invitation" },
    Decline_invitation: {
        fr: "Refuser l'invitation",
        en: "Decline invitation",
    },
    Hotel_Access: { fr: "Accéder à mes hôtels", en: "Access to my hotels" },

    Rooms_description: {
        fr: "Vue d’ensemble des chambres de l’hôtel",
        en: "Overview of hotel rooms",
    },
    Rooms_description_temoin: {
        fr: "Vue d’ensemble des chambres témoin de l’hôtel",
        en: "Overview of hotel rooms",
    },
    donation_title: { fr: "Mon impact RSE", en: "My CSR impact" },
    Donation_certificate: {
        fr: "Certificats de don d'eau",
        en: "Water donation certificates",
    },
    Learn_More_Water_Donation: {
        fr: "En savoir plus sur le don d’eau",
        en: "Find out more about donating water",
    },
    Made_Blue_Found: {
        fr: "L'association MadeBlue Fondation",
        en: "The MadeBlue Foundation",
    },
    Made_Blue_description: {
        fr: "Made Blue est une association dynamique engagée dans la réalisation de projets d'accès à l'eau potable, principalement dans des régions souffrant de stress hydrique permanent. Depuis sa création en 2014, Made Blue s'est concentrée sur la fourniture d'eau propre et l'hygiène, enregistrant un impact considérable : plus de 14,2 milliards de litres d'eau potable fournis et plus de 194 000 personnes ayant accès à l'eau. Made Blue est également innovant dans ses projets, tels que l'installation de distributeurs d'eau en Tanzanie et la promotion de l'autonomie des communautés locales dans la gestion de l'accès à l'eau. En outre, l'organisation contribue à la durabilité environnementale, avec une réduction significative des émissions de CO2.",
        en: "Made Blue is a dynamic association committed to implementing projects providing access to drinking water, mainly in regions suffering from permanent water stress. Since its creation in 2014, Made Blue has focused on providing clean water and hygiene, recording a considerable impact: more than 14.2 billion litres of drinking water supplied and more than 194,000 people with access to water. Made Blue is also innovative in its projects, such as installing water distributors in Tanzania and promoting the autonomy of local communities in managing access to water. The organisation also contributes to environmental sustainability, with a significant reduction in CO2 emissions.",
    },
    Room_graph_title: {
        fr: "Evolution des consommations d'eau et d'énergie",
        en: "Evolution of water and energy consumption",
    },
    Room_TimePeriod: {
        fr: "Sur la période du",
        en: "During the period from",
    },
    Room_TimePeriod_limit: {
        fr: "au",
        en: "to",
    },
    Room_Water_Legend: {
        fr: "Eau (L)",
        en: "Water (L)",
    },
    Room_Energy_Legend: {
        fr: "Énergie (Wh)",
        en: "Energy (Wh)",
    },
    Mes_hôtels: {
        fr: "Mes hôtels",
        en: "My hotels",
    },
    Connection_required_Hotel: {
        fr: "Pour accéder à vos hôtels, il faut se connecter",
        en: "To access your hotels, you need to sign in",
    },
    Connection_required_Info: {
        fr: "Pour voir vos informations, veuillez vous connecter",
        en: "To access your information, please sign in",
    },
    Loader: {
        fr: "Chargement...",
        en: "Loading...",
    },
    monthsGraph: {
        fr: [
            "janvier",
            "février",
            "mars",
            "avril",
            "mai",
            "juin",
            "juillet",
            "août",
            "septembre",
            "octobre",
            "novembre",
            "décembre",
        ],
        en: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
    },
    SubGraphLegend_totalSavings : {
        fr: "Économies totale réalisées",
        en: "Total savings",
    },
    SubGraphLegend_realCost : {
        fr: "Coût selon contrat",
        en: "Cost according to contract",
    },
    SubGraphLegend_provCost : {
        fr: "Coût provisionné",
        en: "Provisioned cost",
    },
    SubGraphLegend_regularization : {
        fr: "Régularisation",
        en: "Regularization",
    },
    SubGraphLegend_quarter : {
        fr: "Trimestre",
        en: "Quarter",
    },
    SubGraphTitle : {
        fr: "Bilan financier par trimestre",
        en: "Financial report per quarter",
    },

};

const Translation = (props) => {
    const { lang } = useContext(HotelContext);
    const supportedLang = ["fr", "en"];

    if (!supportedLang.includes(lang)) {
        return "Unsupported Langage";
    }

    let toDisplay;
    if (translation[props.tag]) {
        toDisplay = translation[props.tag][lang];
    } else {
        toDisplay = props.tag;
    }

    return toDisplay;
};

export default Translation;
